.amd-bs5 {
    // Set corporate black and white. Do not change them.
    --c-black: #{$corporate-black};
    --c-white: #{$corporate-white};
    // Set default for foreground and background.
    --foreground: var(--c-black);
    --background: var(--c-white);

    //default
    .btn-Primary-Black a, .button a, button.btn, a.btn, input.btn, input[type="submit"], input[type="reset"], input[type="button"], .teaser-product .card-link:first-child {
        --primary: var(--foreground, --c-black);
        --secondary: var(--background, --c-white);
        @include btn-primary;
    }
    .btn-Primary-White a, .teaser-product.teaser-light .card-link:first-child {
        --primary: var(--background, --c-white);
        --secondary: var(--foreground, --c-black);
        @include btn-primary;
    }
    .btn-Expressive a {
        color: $corporate-white;
        @include Expressive-Bkg;
        font-weight: 600;
        @include generate-spacing(padding, 40, 'y');
        @include generate-spacing(padding, 50, 'x');
        border-radius: 2px;
        text-decoration: none;
        box-shadow: -1px 1px 0 #000, inset -1px 1px 0 hsl(0deg 0% 52.2% / 62%);
        text-shadow: 0px 1px 3px hsl(0deg 0% 0% / 70%);
        border: 0px;
        &:hover,
        &:focus {
            color: $corporate-white;
            border-color: none;
            box-shadow: -1px 1px 0 #000000, inset -1px 1px 0 rgba(255, 255, 255, 0.2);
            @include Expressive-Bkg-Hover;
            text-decoration: none;
        }
    }
    .btn-Secondary-White a, .teaser-product.teaser-light .card-link {
        --secondary: var(--background, --c-white);
       @include btn-secondary;
    }
    .btn-Secondary-Black a, .teaser-product .card-link {
        --secondary: var(--foreground, --c-black);
        @include btn-secondary;
    }
    .btn-Secondary-Accent-Light a {
        --secondary: var(--accessible-light, #{$access-link-light});
        @include btn-secondary;
    }
    .btn-Secondary-Accent-Dark a {
        --secondary: var(--accessible-dark, #{$access-link-dark});
        @include btn-secondary;
    }
    .btn {
		display: inline-flex;
        @include generate-spacing(margin, 45, 'b');
        padding: 0;
        white-space: normal;
        & a>i {
            padding-left:map-get($spacers-clamped, 35 );
        }
    	&.noWrap {
			white-space: nowrap;
		}
        &.btn-small {
            &.btn-Primary-Black a,
    	    &.btn-Primary-White a,
            &.btn-Expressive a {
                @include generate-spacing(padding, 40, 'xy');
            }
            &.btn-Secondary-White a,
            &.btn-Secondary-Black a,
            &.btn-Secondary-Accent-Light a,
            &.btn-Secondary-Accent-Dark a {
                @include generate-spacing(margin, 0, 'b');
                @include generate-spacing(padding, 0, 'xy');
            }
        }
        
        &.btn-stack {
            display: block;
            text-align: left;
        }
    }

    /* target the primary only */
    .button.btn.btn-Primary-White.btn-stack,
    .button.btn.btn-Expressive.btn-stack { 
        @include generate-spacing(margin, 55, 'b');
    }

    /* target the secondary only */
    .button.btn.btn-Secondary-Black.btn-stack,
    .button.btn.btn-Secondary-White.btn-stack,
    .button.btn.btn-Secondary-Accent-Light.btn-stack,
    .button.btn.btn-Secondary-Accent-Dark.btn-stack {
        @include generate-spacing(margin, 35, 'b');
    } 

    div.btn {
        cursor: default;
        button, input[type="submit"], input[type="reset"], input[type="button"], a[data-bs-toggle=modal] {
            cursor:pointer;
        }
        /* target the default only*/
        &.btn-small a {
            @include generate-spacing(padding, 40, 'xy');
        }
        
        &.btn-stack {
            @include generate-spacing(margin, 55, 'b');
        }
    }
    .btn-Solid-Black a {
        --primary: var(--background, --c-white);
        --secondary: var(--foreground, --c-black);
        background-color: var(--secondary);
        @include btn-primary;
        &:hover,
        &:focus {
           border-color: var(--secondary);
        }
    }
}