.amd-bs5 {
  .relatedContent {
    --text-color: #{$corporate-black};
    --light-text-color: #{$neutral-60};
    --card-blur: none;
    @include teaser-card-image();

    .card-body {
      @include teaser-card-body();
      @include teaser-card-text();

      .card-title {
        @include teaser-title();
      }
    }
  }
  .relatedContent.text-light {
    --text-color: #{$corporate-white};
    --light-text-color: #{$neutral-40};
    --card-blur: blur(5px);
    .card.related-content-card {
      background-color: rgba($neutral-90, .8);
    }
  }
  .card.related-content-card {
    color: var(--text-color);
    background-color: $corporate-white;
    backdrop-filter: var(--card-blur);
    @include teaser-card;
    
    &:hover .card-title>a {
      text-decoration: underline;
    }
    // opacity: var(--card-opacity);
    .card-full-link {
      position: absolute;
      left: 0;
      top: 0;
      inset: 0;
    }
    .card-title a {
      color: var(--text-color);
      font-weight: 600;
    }
    .card-date {
      color: var(--light-text-color);
      font-size: $font-size-sm;
    }

    img.related-content-image {
      padding-bottom: 0;
      transition: transform .3s, visibility .3s ease-in-out;
      transform-origin: center;
      width: 100%;
    }
    .card-image-top {
      overflow: hidden;
    }
    &:hover, &:focus-within {
      box-shadow: $lifted;
      img {
        transform: scale(1.1);
      }
    }
  }
  [data-component="related-content"] .col {
    @include generate-spacing(margin, 60, 'b');
  }

}
