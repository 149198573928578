.amd-bs5 {
  .btn-feedback.open-feedback-button {
    position: fixed;
    color: $corporate-white;
    @include Expressive-Bkg;
    z-index: 3000;
    padding: .625rem 1rem;
    font-size: .875rem;
  }

  .btn-feedback {
    transform-origin: bottom right;
    transform: rotate(270deg) translateX(100%);
    white-space: nowrap;
    right: 0;
    bottom: 10%;
  }

  #feedbackModal {
    .modal-title {
      color: $corporate-white;
      margin-bottom: 0;
      padding-left: 1rem;
      white-space: nowrap;
      h5 {
        font-weight: 500;
      }
    }
    .close-feedback-button {
      @include media-breakpoint-down(sm) {
        display: none;
      }
      position: absolute;
      right: 100%;
      z-index: 3070;
      color: $neutral-60;
      background-color: var(--secondary);
      padding: .625rem 1rem;
      font-size: .875rem;

      &:hover {
        color: $corporate-black;
        border: 1px solid $neutral-60;
      }
    }

    .modal-header {
      border-radius: 0;
      padding: 0;
    }

    .btn-close {
      padding: 1.5rem;
    }

    .modal-right {
      position: absolute;
      right: 0;
      background-color: $gray-100;
    }

    .modal-dialog {
      padding: 0;
      transform: translateX(100%);
      transition: transform 1s;
      width: min(390px, 100%);
      bottom: 90px;
      max-height: calc(100vh - 90px);
      margin-right: 0;
    }


    &.show .modal-dialog {
      transform: translateX(0);
      iframe {
        width: 100%;
        height: min(calc(100vh - 110px), 570px);
      }
    }

    .modal-body {
      background-color: #ffffff;
      padding: 0;
    }

    .feedback-submit-row, .feedback-support-row, .feedback-comments-row, .feedback-rating-row {
      margin-top: 1rem;
    }

    .cmp-form-button btn {
      margin-bottom: map-get($spacers, 25);
      padding: map-get($spacers, 35) map-get($spacers, 40);
    }

    --primary: #{$neutral-80};
    --secondary: #{$gray-100};

  }
}
