body.modal-open .amd-bs5 .container-parsys>.cmp-container>.cmp-container__content {
  z-index: unset;
}

.aem-AuthorLayer-Edit .amd-bs5 {
  .container-parsys {
    .slide-right, .slide-left, .slide-up, .slide-down, .fade-in {
      &>.cmp-container {
        opacity: 1;
        overflow: auto;
        transform: none;
      }
    }
  }
}
.amd-bs5 {
  .container-parsys {
    --bg-image-position: center;	
    .cmp-container {
      &.teal-border {
        @include teal-border;
      }
      &.gold-border {
        @include gold-border;
      }
      &.neutral-border {
        @include neutral-border;
      }
      &.orange-border {
        @include orange-border;
      }
    }
    &.slide-right>.cmp-container {
      opacity: 0;
      transform: translateX(-150%);
      overflow: hidden;
    }
    &.slide-left>.cmp-container {
      opacity: 0;
      transform: translateX(150%);
      overflow: hidden;
    }
    &.slide-up>.cmp-container {
      opacity: 0;
      transform: translateY(150%);
      overflow: hidden;
    }
    &.slide-down>.cmp-container {
      opacity: 0;
      transform: translateY(-150%);
      overflow: hidden;
    }
    &.fade-in>.cmp-container {
      opacity: 0;
      overflow: hidden;
    }
    &.fixed-parallax>.cmp-container {
      clip-path: inset(0);
      margin-top: -1px;
      margin-bottom: -1px;
    }

    &.fixed-parallax>.cmp-container>.cmp-container__image>picture>img {
      position: fixed;
      left: 0;
      top: 0;
    }
    &.fixed-parallax>.cmp-container>.cmp-container__image {
      clip-path: inset(1px);
    }

    .cmp-container__image img {
      object-position: var(--bg-image-position);
    }
    &.bg-left {
      --bg-image-position: left;
    }

    &.bg-left {
      --bg-image-position: left top;
    }

    &.bg-left {
      --bg-image-position: left bottom;
    }

    &.bg-right {
      --bg-image-position: right;
    }

    &.bg-right {
      --bg-image-position: right top;
    }

    &.bg-right {
      --bg-image-position: right bottom;
    }

    &.bg-top {
      --bg-image-position: top;
    }

    &.bg-bottom {
      --bg-image-position: bottom;
    }
    
    &.bg-top>.cmp-container>.cmp-container__image.width {
      align-items: flex-start;
    }

    &.bg-bottom>.cmp-container>.cmp-container__image.width {
		align-items: flex-end;
    }  
    
    &.slab-top,
    &.slab-middle,
    &.slab-bottom {
      &>.cmp-container {
        &>.cmp-container__content {
          position: absolute;
          width: 100%;

          @include media-breakpoint-down(xl) {
            position: static;
          }
        }

        &>.cmp-container__image,
        &>.cmp-container__video {
          position: relative;
        }
      }
    }

    @include media-breakpoint-up(xl) {
      &.slab-top>.cmp-container>.cmp-container__content {
        top: 0;
      }

      &.slab-middle>.cmp-container>.cmp-container__content {
        top: 50%;
        transform: translateY(-50%);
      }

      &.slab-bottom>.cmp-container>.cmp-container__content {
        top: 100%;
        transform: translateY(-100%);
      }
    }

    &>.cmp-container {
      position: relative;

      &>.cmp-container__content {
        position: relative;
        z-index: 1;

        &.bkgGradient {
          background: linear-gradient(90deg, rgba(0, 0, 0, 0.9009978991596639) 2%, rgba(0, 0, 0, 0) 54%, rgba(0, 0, 0, 0) 100%);
        }

        .image {
          line-height: 0;
        }

        &>div {
          height: 100%;
        }
      }

      &>.cmp-container__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        
        &>picture>img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }        

        &.noCrop {
          position: unset;
        }
        
		&.auto {
			&>picture>img {	
				object-fit: none;
			}
		}
		
		&.contain {
			&>picture>img {	
				object-fit: contain;
			}
		}
		
		&.width {
			overflow: hidden;
			display: flex;
			align-items: center;
			&>picture {
				flex-grow: 1;
				>img {	
		        	object-fit: unset;
			        height: auto;
				}				
			}					
		}
      }

      &>.cmp-container__video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        line-height: 0;

        &.noCrop {
          position: unset;
        }

        &.disablevid {
          @include media-breakpoint-down(lg) {
            .video_pause {
              display: none;
            }
          }
        }

        &.disablevidWrap {
          @include media-breakpoint-down(lg) {
            position: absolute;
          }

          &.noCrop {
            @include media-breakpoint-down(lg) {
              position: unset;
            }
          }
        }

        @include media-breakpoint-down(lg) {
          position: relative;
        }

        video {
          height: 100%;

          &.cover {
            object-fit: cover;
          }

          &.contain {
            object-fit: contain;
          }

          &.auto {
            object-fit: auto;
          }
        }

        button {
          aspect-ratio: 1/1;
          background-color: transparent;
          border-radius: 50%;
          bottom: 1.3rem;
          position: absolute;
          right: 1.3em;
          width: 3em;
          z-index: 2;
          border: 2px solid rgba(116, 117, 121,.7);
          color: $neutral-50;
          backdrop-filter: blur(30px);


        @media (prefers-reduced-motion) {
            display: none;
          }
        }
      }
    }
	@include media-breakpoint-down(sm) {
		&.mobile-image {
			>.cmp-container {
				>.cmp-container__image {
					position: relative !important;
					>.cmp-container__image {
                      position: absolute;
                    }
				}
				>.cmp-container__content {
					align-items: flex-end;
				}
			}
		}
	}
  }

  .content-wrapper .cmp-container>.cmp-container__content>.aem-Grid>.container-parsys {
    &>.cmp-container>.cmp-container__content {

      &>.container,
      &>.container-sm,
      &>.container-md,
      &>.container-lg,
      &>.container-xl,
      &>.container-xxl {
        @include generate-spacing(padding, 60, 'y');
      }
    }

    &.container-padding-none>.cmp-container>.cmp-container__content {

      &>.container,
      &>.container-sm,
      &>.container-md,
      &>.container-lg,
      &>.container-xl,
      &>.container-xxl {
        @include generate-spacing(padding, 0, 'y');
      }
    }

    &.container-padding-lg>.cmp-container>.cmp-container__content {

      &>.container,
      &>.container-sm,
      &>.container-md,
      &>.container-lg,
      &>.container-xl,
      &>.container-xxl {
        @include generate-spacing(padding, 70, 'y');
      }
    }

    &.container-padding-xlg>.cmp-container>.cmp-container__content {

      &>.container,
      &>.container-sm,
      &>.container-md,
      &>.container-lg,
      &>.container-xl,
      &>.container-xxl {
        @include generate-spacing(padding, 75, 'y');
      }
    }
  }
}