.amd-bs5 {
  color: var(--foreground);
  --highlight: var(--foreground);
  strong {
    color: var(--highlight);
  }
  .bigNumber {
    &.light-text {
      --foreground: #{$corporate-white};
      &.highlight-teal {
        --highlight: #{$primary-teal};
      }
    }
    &.highlight-orange {
      --highlight: #{$primary-orange};
    }
    &.highlight-gold {
      --highlight: #{$primary-gold};
    }
    &.highlight-teal {
      --highlight: #{$primary-teal-dark};
    }
    &.size-jumbo {
      .middle-text strong {
        @include jumbo;
      }
    }
    &.arrow-down {
      .arrow-container svg.arrow-down-svg {
        display: block;
      }
    }
    &.arrow-up {
      .arrow-container svg.arrow-up-svg {
        display: block;
      }
    }
    span {
      display: block;
    }
    .top-text {
      @include subheadline;
      margin-bottom: 0;
    }
    .middle-text {
      @include subheadline;
      strong {
        @include h2-expressive;
      }
      margin-bottom: 0;
    }
    .bottom-text {
      @include subheadline;
      margin-bottom: 0;
    }
    .disclaimer {
      font-style: italic;
    }
    .arrow-container svg {
      display: none;
      right: 0;
      top: 0;
      height: 75px;
      position: absolute;
      aspect-ratio: 1 / 1;
      path {
        fill: var(--highlight);
      }
    }
  }
}